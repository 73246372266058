import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import { createBrowserHistory } from "history";
import HomePage from "../Pages/HomePage/HomePage";

const customHistory = createBrowserHistory();

// Routes For Navigation

const MyRoutes = (props) => {

  return(

    <BrowserRouter history={customHistory}>
      <div>
          <Routes>
              <Route exact path="/" element={<HomePage />}></Route>
          </Routes>
      </div>
    </BrowserRouter>

  );

}

export default MyRoutes;