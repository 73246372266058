import LanguageCard from "../../Components/LanguageCard";
import '../../Styles/Components/LanguageCard.css'

function Languages(){

    const Languages_data = [
        {
            icon:'A',
            title:'English',
            description:[
                {star:'bx bxs-star'},
                {star:'bx bxs-star'}, 
                {star:'bx bxs-star'}, 
                {star:'bx bxs-star'}, 
                {star:'bx bxs-star'} 
            ]
        },
        {
            icon:'അ',
            title:'Malayalam',
            description:[
                {star:'bx bxs-star'},
                {star:'bx bxs-star'}, 
                {star:'bx bxs-star'}, 
                {star:'bx bxs-star'}, 
                {star:'bx bxs-star'} 
            ]
        },
        {
            icon:'अ',
            title:'Hindi',
            description:[
                {star:'bx bxs-star'},
                {star:'bx bxs-star'}, 
                {star:'bx bxs-star'}, 
                {star:'bx bxs-star'}, 
                {star:'bx bxs-star-half'} 
            ]
        },
    ]

    return(
        <section className='container professional-experience'>
            <h2 class="section-header-text">Languages</h2>
            <div className="row features-language">
                {
                    Languages_data.map((language) =>{
                        return(
                            <LanguageCard data={language}/>
                        )
                    })
                }
            </div>
        </section>
    )
}

export default Languages;