import '../Styles/Components/ContentCard.css'

function ContentCard(props){
    return(
        <div class="card">
            <span><i className={props.data.icon}></i></span>
            <h4>{props.data.title}</h4>
            <div className='mt-2'>
                {
                    props.data.description.map((item)=>{
                        return(
                            <img src={item.logo} width='25' height='25' className='me-4' title={item.tooltip}/>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default ContentCard;