import hbsimg from '../Assets/Images/hbs_web.png';
import Python from '../Assets/Images/python.png';
import '../Styles/Components/Project.css';

function ProjectCard(props){

    const navigateLink = (value, url) =>{
        if(value === 'github'){
            window.open(url, '_blank').focus();
        }
        else if(value === 'youtube'){
            window.open(url, '_blank').focus();
        }
    }

    return(
        <div className='card'>
            <div className='col d-flex flex-column'>
                <div className='d-flex justify-content-center mt-3'>
                    <img className='project-image' src={props.data.image}/>
                </div>
                <span className='project-name'>{props.data.name}</span>
                <span className='project-desc'>{props.data.desc}</span>
                <div className='row'>
                    <div className='project-tools col'>
                        {props.data.tools.map((tool) =>{
                            return(
                                <img src={tool.icon} width='25' height='25' className='me-3 mb-3' title={tool.tooltip}/>
                            )
                        })}
                    </div>
                    <div className='project-icons col d-flex justify-content-end mt-2'>
                        <span className='footer-icons me-4'><i class='bx bxl-youtube' onClick={()=>navigateLink('youtube',props.data.youtube_url)}></i></span>
                        <span className='footer-icons me-4'><i class='bx bxl-github' onClick={()=>navigateLink('github',props.data.github_url)}></i></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectCard;