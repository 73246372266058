import '../Styles/Components/LanguageCard.css'

function LanguageCard(props){
    return(
        <div class="card">
            <span className='icon-background'>{props.data.icon}</span>
            <h4 className='language-title'>{props.data.title}</h4>
            <div className='mt-2'>
                {
                    props.data.description.map((item)=>{
                        return(
                            <span className='star-icon'><i className={item.star}></i></span>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default LanguageCard;