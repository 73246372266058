import ExperienceCard from '../../Components/ExperienceCard';
import '../../Styles/Components/ProfessionalExperience.css'

import Python from '../../Assets/Images/python.png';
import Java from '../../Assets/Images/java.png';
import ReactLogo from '../../Assets/Images/react.png';
import CSharp from '../../Assets/Images/csharp.png';
import JavaScript from '../../Assets/Images/JavaScript.png'
import Flutter from '../../Assets/Images/flutter.png';
import Android from '../../Assets/Images/android.png';
import Github from '../../Assets/Images/github.png';
import Jira from '../../Assets/Images/jira.png';
import Jenkins from '../../Assets/Images/jenkins.png';
import Azure from '../../Assets/Images/azure.png';
import AWS from '../../Assets/Images/aws.png';
import Opteamix from '../../Assets/Images/opteamix.png';
import Teqnocart from '../../Assets/Images/teqnocart.png';
import Muthoot from '../../Assets/Images/muthoot.png';
import Oracle from '../../Assets/Images/oracle.png';
import Powerbi from '../../Assets/Images/powerbi.png';

function ProfessionalExperience(){

    const experience_data = [
        {
            name:'Muthoot Microfin Limited',
            logo:Muthoot,
            role:'Deputy Manager',
            duration:'Currently Working',
            technologies:[
                {technology:'Python', logo:Python},
                {technology:'C#', logo:CSharp}, 
                {technology:'React', logo:ReactLogo}, 
                {technology:'JavaScript', logo:JavaScript},
                {technology:'Powerbi', logo:Powerbi},
                {technology:'Github', logo:Github},
                {technology:'Jira', logo:Jira},
                {technology:'AWS', logo:AWS},
                {technology:'OCI', logo:Oracle},
            ]
        },
        {
            name:'Opteamix',
            logo:Opteamix,
            role:'Software Engineer',
            duration:'2 Years 4 Months',
            technologies:[
                {technology:'C#', logo:CSharp}, 
                {technology:'Python', logo:Python}, 
                {technology:'React', logo:ReactLogo}, 
                {technology:'JavaScript', logo:JavaScript},
                {technology:'Github', logo:Github},
                {technology:'Jira', logo:Jira},
                {technology:'Jenkins', logo:Jenkins},
                {technology:'AWS', logo:AWS},
            ]
        },
        {
            name:'Teqnocart Innovations',
            logo:Teqnocart,
            role:'Full Stack Developer Intern',
            duration:'1 Year',
            technologies:[
                {technology:'Python', logo:Python}, 
                {technology:'Java', logo:Java},
                {technology:'React', logo:ReactLogo},  
                {technology:'JavaScript', logo:JavaScript},
                {technology:'Flutter', logo:Flutter},
                {technology:'Android', logo:Android},
                {technology:'Github', logo:Github},
                {technology:'Azure', logo:Azure},
            ]
        },
    ]

    return(
        <section className='container professional-experience'>
            <h2 class="section-header-text">Professional Experiences</h2>
            <div className="row features-experience">
                {
                    experience_data.map((experience) =>{
                        return(
                            <ExperienceCard data={experience}/>
                        )
                    })
                }
            </div>
        </section>
    )
}

export default ProfessionalExperience;