import SkillsCard from "../../Components/SkillsCard";
import '../../Styles/Components/SkillSet.css'

import CSharp from '../../Assets/Images/csharp.png';
import Python from '../../Assets/Images/python.png';
import Java from '../../Assets/Images/java.png';
import JavaScript from '../../Assets/Images/JavaScript.png'
import Flutter from '../../Assets/Images/flutter.png';
import VersionControl from '../../Assets/Images/versioncontrol.png';
import Cloud from '../../Assets/Images/cloud.png';
import Database from '../../Assets/Images/database.png';

function SkillSet(){

    const skill_sets = [
        {
            logo:CSharp,
            frameworks:'Specialization: .NET Core, ASP.NET, .NET MVC'
        },
        {
            logo:Java,
            frameworks:'Specialization: Springboot, Android'
        },
        {
            logo:Python,
            frameworks:'Specialization: Pandas, Numpy, Matplotlib, Flask, Django'
        },
        {
            logo:JavaScript,
            frameworks:'Specialization: React.js, Redux, Angular'
        },
        {
            logo:Database,
            frameworks:'Specialization: MySQL, MSSQL, MongoDB'
        },
        {
            logo:Cloud,
            frameworks:'Specialization: Azure, AWS'
        },
        {
            logo:Flutter,
            frameworks:'Specialization: Flutter'
        },
        {
            logo:VersionControl,
            frameworks:'Specialization: Github, GitLab, JIRA, Jenkins'
        }
    ]

    return(
        <section className='container professional-experience'>
            <h2 class="section-header-text">Technologies & Frameworks</h2>
            <div className="features-skillset">
                {
                    skill_sets.map((skill) =>{
                        return(
                            <SkillsCard data={skill}/>
                        )
                    })
                }
            </div>
        </section>
    );
}

export default SkillSet;