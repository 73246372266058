import '../Styles/Components/Navbar.css';
import Logo from '../Assets/Images/logo.png';
import { useState } from 'react';
import * as Constants from '../Config/Constants';

function Navbar(){

    const[mouseEntered,setMouseEntered] = useState(false);

    const onDownloadClicked = () =>{
        const pdfUrl = Constants.CV_URL;
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.target = '_blank';
        link.download = "jithin_babu_cv.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return(
        <nav>
            <div className="nav-logo">
                <a href="/">
                    <img src={Logo} className='navbar-logo'/>
                </a>
            </div>
            <div className='btn-download d-flex align-items-center justify-content-center' onMouseEnter={()=>{setMouseEntered(true)}} onMouseLeave={()=>{setMouseEntered(false)}} onClick={onDownloadClicked}>
                <span>Download CV</span>
                {mouseEntered === true ? <span className='ms-2 mt-1'><i class='bx bx-cloud-download download-icon'></i></span>: null}
            </div>
        </nav>
    );
}

export default Navbar;