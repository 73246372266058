import TestimonialCard from '../../Components/TestimonialsCard';
import '../../Styles/Components/Testimonials.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function Testimonials() {

    const Testimonial_data = [
        {
            testimonial:'Jithin is very intelligent, quick learner and a very nice human. He is one among the two best intelligent student I ever had. He is an excellent team player and can lead projects too.',
            author:'Dr. Anishin Raj M M',
            relation:'Mentor at VJCET',
        },
        {
            testimonial:'Jithin consistently demonstrated a remarkable aptitude for grasping complex concepts and applying them in innovative ways. His ability to think critically and analyze problems from multiple angles was truly impressive.',
            author:'Dr. Diana Baby',
            relation:'Colleague at Muthoot Microfin',
        },
        {
            testimonial:'Jithin is an exceptional developer who possesses all the skills one would want in an excellent software developer. From Python to C# and .NET to AI, he masters the top programming languages, technologies, and frameworks. He did an incredible job on all projects, making timely deliveries. His work is always top-notch, and he is always welcoming to feedback and making improvements.',
            author:'Helen Harit',
            relation:'Tech Lead at Opteamix',
        }
    ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };

    return (
        <section className='container professional-experience'>
            <h2 class="section-header-text">Testimonials</h2>
            <Slider className='features-testimonial' settings={settings}>
                {
                    Testimonial_data.map((testimonial) =>{
                        return(
                            <TestimonialCard data={testimonial}/>
                        )
                    })
                }
            </Slider>
            
        </section>
    );
}
  
export default Testimonials;