import HomePageHeader from "./HomePageHeader";
import Navbar from "../../Components/Navbar";
import Areaofinterest from "./Areaofinterest";
import ProfessionalExperience from "./ProfessionalExperience";
import SkillSet from "./Skillset";
import Education from "./Education";
import Languages from "./Languages";
import Publications from "./Publications";
import Achievements from "./Achievements";
import Testimonials from "./Testimonials";
import Footer from "./Footer";
import Projects from "./Projects";

function HomePage() {
    return (
        <div>
            <Navbar />
            <HomePageHeader />
            <ProfessionalExperience />
            <Education />
            <Areaofinterest />
            <SkillSet />
            <Publications />
            <Projects />
            <Languages />
            <Achievements />
            <Testimonials />
            <Footer />
        </div>
    );
}
  
export default HomePage;