import VJCET from '../../Assets/Images/vjcet.png';
import HSSV from '../../Assets/Images/hssv.png';
import Gaemhss from '../../Assets/Images/gaemhss.png';
import EducationCard from '../../Components/EducationCard';

function Education (){

    const education_details = [
        {
            logo:VJCET,
            institution:'Viswajyothi College of Engineering and Technology',
            location:'Vazhakulam',
            cgpa:'CGPA: 8.13',
            passout:'Passout year: 2021'
        },
        {
            logo:HSSV,
            institution:'Higher Secondary School',
            location:'Valayanchirangara',
            cgpa:'Percentage: 89%',
            passout:'Passout year: 2017'
        },
        {
            logo:Gaemhss,
            institution:'Guardian Angel English Medium Higher Secondary School',
            location:'Mannoor',
            cgpa:'Percentage: 100%',
            passout:'Passout year: 2015'
        },
    ]

    return(
        <section className='container professional-experience'>
            <h2 class="section-header-text">Academics</h2>
            <div className="row features-experience">
                {
                    education_details.map((education) =>{
                        return(
                            <EducationCard data={education}/>
                        )
                    })
                }
            </div>
        </section>
    );
}

export default Education;