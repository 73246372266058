import '../../Styles/Components/LanguageCard.css'

function Publications(){
    return(
        <section className='container professional-experience'>
            <h2 class="section-header-text">Publications</h2>
            <div class="card publication-card">
                <span className="publication-name">Jithin Babu, Joseph Benny, Rahul J, Jetson Joseph and Dr. Anishin Raj M M, “HbS Disease Detection: An Image Processing Methodology For The Detection Of Sickle Cell Anemia”, Technology and Future, Volume VII, Issue 2, Page no 17-22, 2020</span>
            </div>  
        </section>
    )
}

export default Publications;