
function EducationCard(props){
    return(
        <div className="card">
            <div className='row'>
                <div className='experience-card-1 col-4'>
                    <img className='experience-image' src={props.data.logo} />
                </div>
                <div className='experience-card-2 col d-flex flex-column'>
                    <span className='experience-name'>{props.data.institution}</span>
                    <span className='experience-years mt-1'>{props.data.location}</span>
                    <span className='experience-years mt-1'>{props.data.cgpa}</span>
                    <span className='experience-years mt-1'>{props.data.passout}</span>
                </div>
            </div>
        </div>
    )
}

export default EducationCard;