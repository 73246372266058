import '../../Styles/Components/Footer.css';
import Profile from '../../Assets/Images/photo.png'
import { useState } from 'react';

function Footer(){

    const[deviceWidth, setDeviceWidth] = useState(window.innerWidth);

    const[openContact, setOpenContact] = useState(false);
    const[closeButton, setCloseButton] = useState(false);

    const navigateLink = (value) =>{
        if(value === 'blog'){
            window.open('http://quantumquest.co.in/', '_blank').focus();
        }
        else if(value === 'linkedin'){
            window.open('https://www.linkedin.com/in/jithinbabu8281/', '_blank').focus();
        }
        else if(value === 'github'){
            window.open('https://github.com/Jithinmalilan', '_blank').focus();
        }
        else if(value === 'youtube'){
            window.open('https://www.youtube.com/@quantumquest5799', '_blank').focus();
        }
    }

    return(
        <footer className='container footer-container'>
            <div className='row'>
                {
                    deviceWidth > 500 ? 
                    <div className='col-lg-4 col-md-4 col-sm-3 mt-5'>
                        <hr className='footer-hr'/>
                    </div>
                    :null
                }
                <div className='col-lg-4 col-md-4 col-sm-6 d-flex justify-content-center footer-photo'>
                    <img src={Profile} width='175'/>
                </div>
                {
                    deviceWidth > 500 ? 
                    <div className='col-lg-4 col-md-4 col-sm-3 mt-5'>
                        <hr className='footer-hr'/>
                    </div>
                    :null
                }
            </div>
            <div className='d-flex justify-content-center'>
                <span className='footer-title'>Jithin Babu</span>
            </div>
            <div className='d-flex justify-content-center mt-3'>
                {
                    closeButton === false ?
                    <div className='footer-mail-container d-flex align-items-center justify-content-center' onClick={()=>{setOpenContact(true);setCloseButton(true);}}>
                        <span><i class='bx bx-info-circle me-2 footer-mail-icon pt-1'></i></span>
                        <span>Get in Touch</span>
                    </div>
                    :null
                }
                {
                    openContact === true ?
                    <div className='footer-mail-container d-flex flex-column' onClick={()=>{setOpenContact(false);setCloseButton(false);}}>
                        <span><i class='bx bxs-phone me-2'></i> +91-8281021671</span>
                        <span className='mt-4'><i class='bx bxl-gmail me-2'></i> jithin@codehazvi.in</span>
                    </div>
                    :
                    null
                } 
                
            </div>
            <div className='row mt-5'>
                {
                    deviceWidth > 500 ?
                    <div className='col-lg-6 col-md-6 col-sm-12 d-flex flex-column'>
                        <span className='footer-content'><i class="fas fa-map-marker-alt me-3"></i> Perumbavoor, Kerala, India</span>
                        <span className='footer-content mt-4' onClick={()=>navigateLink('blog')}><i class='bx bx-world me-2'></i> Quantum Quest</span>
                    </div>
                    :
                    <div className='col-12 d-flex flex-column footer-content-container'>
                        <span className='footer-content'><i class="fas fa-map-marker-alt me-3"></i> Perumbavoor, Kerala, India</span>
                        <span className='footer-content mt-4' onClick={()=>navigateLink('blog')}><i class='bx bx-world me-2'></i> Quantum Quest</span>
                    </div>
                }
                
                {
                    deviceWidth > 500 ?
                    <div className='col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end flex-column'>
                        <div className='d-flex justify-content-end'>   
                            <span className='footer-icons me-4' onClick={()=>navigateLink('github')}><i class='bx bxl-github'></i></span>
                            <span className='footer-icons me-4 mt-1' onClick={()=>navigateLink('youtube')}><i class='bx bxl-youtube' ></i></span>
                            <span className='footer-icons' onClick={()=>navigateLink('linkedin')}><i class='bx bxl-linkedin'></i></span>
                        </div>
                    </div>
                    :
                    <div className='col-12 d-flex justify-content-center mt-5'>
                        <div className='d-flex justify-content-center'>   
                            <span className='footer-icons me-4' onClick={()=>navigateLink('github')}><i class='bx bxl-github'></i></span>
                            <span className='footer-icons me-4 mt-1' onClick={()=>navigateLink('youtube')}><i class='bx bxl-youtube' ></i></span>
                            <span className='footer-icons' onClick={()=>navigateLink('linkedin')}><i class='bx bxl-linkedin'></i></span>
                        </div>
                    </div>
                }
                
            </div>
            <div className='d-flex justify-content-center footer-quick-links'>
                <a href='/' className='achievement-link'><span>Home</span></a>
                <a href='/' className='achievement-link'><span className='ms-4'>Experiences</span></a>
                <a href='/' className='achievement-link'><span className='ms-4'>Skills</span></a>
                <a href='/' className='achievement-link'><span className='ms-4'>Projects</span></a>
            </div>
            <div className='d-flex justify-content-center footer-credit-links mt-4'>
                <span><i class='bx bx-copyright me-1'></i>Jithin Babu</span>
            </div>
        </footer>
    )
}

export default Footer;