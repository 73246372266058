import ContentCard from '../../Components/ContentCard';
import '../../Styles/Components/Areaofinterest.css';
import '../../Styles/Components/HomePageHeader.css';

import Python from '../../Assets/Images/python.png';
import Powerbi from '../../Assets/Images/powerbi.png';
import Java from '../../Assets/Images/java.png';
import ReactLogo from '../../Assets/Images/react.png';
import CSharp from '../../Assets/Images/csharp.png';
import JavaScript from '../../Assets/Images/JavaScript.png'
import Flutter from '../../Assets/Images/flutter.png';
import Android from '../../Assets/Images/android.png';
import Github from '../../Assets/Images/github.png';
import Jira from '../../Assets/Images/jira.png';
import Jenkins from '../../Assets/Images/jenkins.png';
import Azure from '../../Assets/Images/azure.png';
import AWS from '../../Assets/Images/aws.png';

function Areaofinterest(){

    const area_data = [
        {
            icon:'ri-database-line',
            title:'Data Analysis',
            description:[
                {logo:Python, tooltip:'Python'}, 
                {logo:Powerbi, tooltip:'PowerBI'},
            ]
        },
        {
            icon:'ri-computer-line',
            title:'Machine Learning',
            description:[{logo:Python, tooltip:'Python'}]
        },
        {
            icon:'ri-brain-line',
            title:'Regenerative AI',
            description:[{logo:Python, tooltip:'Python'}]
        },
        {
            icon:'ri-code-box-line',
            title:'Web Application',
            description:[
                {logo:ReactLogo, tooltip:'React.js'},
                {logo:CSharp, tooltip:'C#'},
                {logo:Java, tooltip:'Java'},
                {logo:Python, tooltip:'Python'},
                {logo:JavaScript, tooltip:'JavaScript'}, 
            ]
        },
        {
            icon:'ri-smartphone-line',
            title:'Mobile Application',
            description:[
                {logo:Flutter, tooltip:'Flutter'}, 
                {logo:Android, tooltip:'Android'}
            ]
        },
        {
            icon:'ri-git-branch-line',
            title:'Project Management & Cloud',
            description:[
                {logo:Github, tooltip:'Github'}, 
                {logo:Jira, tooltip:'Jira'},
                {logo:Jenkins, tooltip:'Jenkins'}, 
                {logo:Azure, tooltip:'Azure'},
                {logo:AWS, tooltip:'AWS'}
            ]
        },
    ]

    return(
        <section className="container area-of-interest">
            <h2 className="section-header-text">Areas of Interest</h2>
            <div className="features">
                {area_data.map((data)=>{
                    return(
                        <ContentCard data={data}/>
                    )
                })}
            </div>
        </section>
    );
}

export default Areaofinterest;