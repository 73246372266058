import ProjectCard from "../../Components/ProjectCard";
import hbsimg from '../../Assets/Images/hbs_web.png';
import Python from '../../Assets/Images/python.png';
import matlab from '../../Assets/Images/matlab.png';
import PHP from '../../Assets/Images/php.png';

function Projects(){

    const project_data = [
        {
            image:hbsimg,
            name:'HBS Detection',
            desc:'Detection of sickle cell anemia using ML techniques',
            tools:[{icon:Python,tooltip:'Python'},{icon:matlab,tooltip:'MATLAB'},{icon:PHP,tooltip:'PHP'},],
            youtube_url:'https://youtu.be/LrudrjTta7o',
            github_url:'https://github.com/Jithinmalilan/HBS-detection'
        }
    ]

    return(
        <section className='container professional-experience'>
            <h2 class="section-header-text">Projects</h2>
            <div className="row features-experience">
                {
                    project_data.map((project) =>{
                        return(
                            <ProjectCard data={project}/>
                        )
                    })
                }
                
            </div>
        </section>
    )
}

export default Projects;