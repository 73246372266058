import '../Styles/Components/SkillsCard.css'
import { useState } from 'react';

function SkillsCard(props){

    const[displayContent, setDisplayContent] = useState(false);

    return(
        <div className="skills-card" onMouseEnter={()=>{setDisplayContent(true)}} onMouseLeave={()=>{setDisplayContent(false)}} onClick={()=>{setDisplayContent(!displayContent)}}>         
            {
                displayContent === true ?
                <div className='skills-card-content d-flex flex-column'>
                    <img src={props.data.logo} width='40' height='40'/>
                    <span className='skills-card-title'>{props.data.frameworks}</span>
                </div> 
                : 
                <div className='skills-card-content d-flex justify-content-center'>
                    <img src={props.data.logo} width='75' height='75'/>
                </div>
            }
        </div>
    );
}

export default SkillsCard;