function AchievementCard(props){
    return(
        <div className="card">
            <div className='row'>
                <div className='experience-card-1 col-4'>
                    <img className='experience-image' src={props.data.logo} />
                </div>
                <div className='experience-card-2 col d-flex flex-column'>
                    <span className='experience-name'>{props.data.achievement}</span>
                    <span className='experience-years mt-1'>{props.data.company}</span>
                    <span className='experience-years mt-1'>{props.data.period}</span>
                    <a className="achievement-link mt-2" href={props.data.url} target="_blank"><span className='experience-years mt-1'>View Certificate</span></a>
                </div>
            </div>
        </div>
    )
}

export default AchievementCard;