import React from 'react';
import MyRoutes from '../Routes/MyRoutes';
import '../Styles/Pages/App.css';

function App() {
  return (
    <div>
      <React.StrictMode>
          <MyRoutes />
      </React.StrictMode>
    </div>
  );
}

export default App;
