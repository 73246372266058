import '../Styles/Components/Testimonials.css';
import IC from '../Assets/Images/ic.png';

function TestimonialCard (props){
    return(
        <div className='card'>
            <div className='col d-flex flex-column'>
                <img src={IC} width='30'/>
                <span className='testimonial-content mt-3 ms-4 me-3'>{props.data.testimonial}</span>
                <hr className='testimonial-hr'/>
                <div className='d-flex justify-content-end mt-3 me-4'>
                    <span className='experience-name'>{props.data.author}</span>
                </div>
                <div className='d-flex justify-content-end mt-2 me-4'>
                    <span className='experience-years'>{props.data.relation}</span>
                </div>
            </div>
        </div>
    )
}   

export default TestimonialCard;