import '../../Styles/Components/HomePageHeader.css'
import ProfilePhoto from '../../Assets/Images/photo.png'
import { useState } from 'react';

function HomePageHeader(){
    
    const[deviceWidth, setDeviceWidth] = useState(window.innerWidth);

    return(
        <header class="container header-component">
            <span class="blur"></span>
            <span class="blur"></span>
            <div className='row'>
                {
                    deviceWidth < 500 ?
                    <div className='col-sm-12 col-md-5 col-lg-5'>
                        <img src={ProfilePhoto} className='image-content'/>
                    </div> : null
                }
                <div className='content col-sm-12 col-md-6 col-lg-6'>
                    <h1>Hi, I'm <span>Jithin</span><br/> Full Stack Developer</h1>
                    <p class="header-desc">
                    "Elevating the digital experience! Full Stack Developer & Data Analyst weaving creativity and insights into captivating websites and analytics. Transforming data into meaningful stories and crafting seamless user journeys. Dive into the art of innovation with me!"
                    </p>
                </div>
                {
                    deviceWidth > 500 ?
                    <div className='col-sm-12 col-md-5 col-lg-5'>
                        <img src={ProfilePhoto} className='image-content'/>
                    </div> : null
                }
            </div>
        </header>
    );
}

export default HomePageHeader;
