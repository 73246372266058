import '../../Styles/Components/LanguageCard.css'
import Opteamix from '../../Assets/Images/opteamix.png';
import VJCET from '../../Assets/Images/vjcet.png';
import AchievementCard from '../../Components/AchievementCard';

function Achievements() {

    const achievement_data = [
        {
            logo:Opteamix,
            achievement:'Top Contributor',
            company:'Opteamix',
            period:'Q2 2022',
            url:'https://jithin.codehazvi.in/documents/top_contributor.png'
        },
        {
            logo:Opteamix,
            achievement:'Top Scorer',
            company:'Opteamix',
            period:'Q2 2022',
            url:'https://jithin.codehazvi.in/documents/top_scorer.png'
        },
        {
            logo:VJCET,
            achievement:'Best Academic Project',
            company:'Viswajyothi College of Engineering and Technology',
            period:'July 2021',
            url:'https://jithin.codehazvi.in/documents/best_project.jpg'
        },
    ]

    return (
        <section className='container professional-experience'>
            <h2 class="section-header-text">Achievements</h2>
            <div className="row features-experience">
                {
                    achievement_data.map((achievement) =>{
                        return(
                            <AchievementCard data={achievement}/>
                        )
                    })
                }
            </div>
        </section>
    );
}
  
export default Achievements;