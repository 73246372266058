import '../Styles/Components/ExperienceCard.css';
import Opteamix from '../Assets/Images/opteamix.png';

function ExperienceCard(props){
    return(
        <div class="card">
            <div className='row'>
                <div className='experience-card-1 col-4'>
                    <img className='experience-image' src={props.data.logo} />
                </div>
                <div className='experience-card-2 col d-flex flex-column'>
                    <span className='experience-name'>{props.data.name}</span>
                    <span className='experience-years mt-1'>{props.data.role}</span>
                    <span className='experience-years mt-1'>{props.data.duration}</span>
                    <div className='mt-2'>
                    {
                        props.data.technologies.map((item)=>{
                            return(
                                <img src={item.logo} width='25' height='25' className='me-4 mt-2 mb-1' title={item.technology} />
                            )
                        })
                    }
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default ExperienceCard;